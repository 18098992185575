import { atom } from "jotai";

const carddatas = atom(
    [
        { cvv: '321', expires: '11/26', numbers: '4110 1212 2323 3454' },
        { cvv: '321', expires: '10/26', numbers: '6510 1212 2323 3454' },
        { cvv: '321', expires: '09/26', numbers: '3710 1212 2323 3454' },
    ]

)


export default carddatas